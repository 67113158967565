a {
  text-decoration: none;
  color: var(--TextPrimary);
}
[data-theme="light"] {
  --site-general-background: #ececec;

  --navigation-bar: #ffff;
  --active-menu-tab: #000;
  --menu-tabs: #848484;

  --card-bg-color: #ffff;

  --div-bg00: #0c0c0c;
  --div-bg01: #e7e7e7;
  --div-bg02: #ffffff;

  --text-color00: #6b6b6b;
  --text-color01: #0c0c0c;

  --text-color11: #ffff;

  --not-live-container-bg: #f5f7f9;
  --live-container-bg: #333;

  --match-list-border: #f6f6f6;


  /*H3 COLORS*/
  --AppColor01: #126e51;
  --H3Dark0: #22252C;
  --H3Dark1: #161920;
  --H3Dark2: #1D2027;
  --H3Dark3: #161920;
  --H3Gradient1: #F82476;
  --H3Gradient2: #F33B00;
  --H3Green01: #00AA56;
  --H3Green60: #F6F7CD;
  --H3Green80: #E2F7D2;
  --H3Green90: #B1E88C;
  --H3Grey05: #A7A8AA;
  --H3Light0: #FFFFFF;
  --H3Light1: #F1F1F1 ;
  --H3Light2: #F0F0F0;
  --H3Live: #CB1818;
  --H3MidGrey1: #808797;
  --H3MidGrey2: #485b74;
  --H3Red01: #CB1818;
  --H3Selected1: #FF2100;
  --H3TableHeader: #1893C1;
  --H3TeamDraw: #F5CEA6;
  --H3TeamFormD: #8D9499;
  --H3TeamFormL: #DD3636;
  --H3TeamFormW: #00985F;
  --H3TeamLost: #F59B98;
  --H3TeamWin: #96BA8D;
  --White01: #FFFFFF;
  --Black01: #000000;
  --H3Text1: #121317;
  --H3Text2: #717171;
  --TextPrimary: #121317;
  --TextSecondary: #717171;
  --TextGrape: #3e2d56;
  --H3Yellow1: #FFCD00;
  --ViewBg1: #E6EAEB;
  --ViewBg2: #F3F6F7;

  --Start: 0.8209476994774838;
  --phoneHeight: 660.2666992187501px;
  --strapSize: 26px;
  --sectionPrimaryColour: #00febb;
  --sectionPrimaryColourP3: color(display-p3 0.157 1.000 0.733);
  --Fixtures: 1;
  --Competitions: 1;
  --Teams: 1;
  --Live: 1;
  --End: 1;
  --transitionStandard: 1s;
  --sectionPrimary: #00febb;
  --systemFont: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Tahoma, Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Open Sans", sans-serif;
}

[data-theme="dark"] {
  /*H3 COLORS*/
  --AppColor01: #126e51;
  --H3Dark0: #FFFFFF;
  --H3Dark1: #FEFFFF;
  --H3Dark2: #F6F6F6;
  --H3Dark3: #161920;
  --H3Gradient1: #F82476;
  --H3Gradient2: #F33B00;
  --H3Green01: #00AA56;
  --H3Green60: #F6F7CD;
  --H3Green80: #E2F7D2;
  --H3Green90: #B1E88C;
  --H3Grey05: #E0E1E2;
  --H3Light0: #22252C;
  --H3Light1: #161920;
  --H3Light2: #1D2027;
  --H3Live: #F8FE11;
  --H3MidGrey1: #808797;
  --H3Red01: #CB1818;
  --H3Selected1: #F8FE11;
  --H3TableHeader: #1893C1;
  --H3TeamDraw: #F5CEA6;
  --H3TeamFormD: #8D9499;
  --H3TeamFormL: #DD3636;
  --H3TeamFormW: #00985F;
  --H3TeamLost: #F59B98;
  --H3TeamWin: #96BA8D;
  --White01: #FFFFFF;
  --Black01: #000000;
  --H3Text1: #FEFFFF;
  --H3Text2: #F6F6F6;
  --H3Yellow1: #FFDE00;

  /*--site-general-background: #383838;

  --navigation-bar: #000;
  --active-menu-tab: #ffff;
  --menu-tabs: #f5ecec;

  --card-bg-color: #2d2d2d;

  --div-bg00: #ffff;
  --div-bg01: #2d2d2d;
  --div-bg02: #414141;

  --text-color00: #f5ecec;
  --text-color01: #ffffff;

  --text-color11: #0c0c0c;

  --not-live-container-bg: #ffff;
  --live-container-bg: #ff0000;

  --match-list-border: #2d2d2d;*/

  --site-general-background: #ececec;

  --navigation-bar: #ffff;
  --active-menu-tab: #000;
  --menu-tabs: #848484;

  --card-bg-color: #ffff;

  --div-bg00: #0c0c0c;
  --div-bg01: #e7e7e7;
  --div-bg02: #ffffff;

  --text-color00: #6b6b6b;
  --text-color01: #0c0c0c;

  --text-color11: #ffff;

  --not-live-container-bg: #f5f7f9;
  --live-container-bg: #333;

  --match-list-border: #f6f6f6;

}

* {
  font-family: Verdana, Arial, Helvetica, sans-serif;
}

.App {
  width: 100%;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}

.theme-mode-icons {
  width: 20px;
  height: 20px;
}

.timezone-icon {
  width: 20px;
  height: 20px;
  margin: 0 20px;
}

.const-width {
  width: 100%;
  /*max-width: 975px;*/
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex: 5;
}

.custom-width-for-score-radar {
  width: 100%;
  /*max-width: 975px;*/
  margin: 0 auto;
  display: flex;
  flex: 5;
}

.const-width-small {
  width: 100%;
  max-width: 650px;
}

.not-live-text {
  color: #6b6b6b;
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
}

.not-live {
  display: flex;
  place-items: center;
  background-color: var(--not-live-container-bg);
  border-radius: 9px;
  justify-content: space-between;
  padding: 7px;
  transition-delay: .1s;
  transition-duration: .3s;
  position: relative;
}


.live-match {
  display: flex;
  place-items: center;
  border-radius: 9px;
  justify-content: space-between;
  padding: 7px;
  background-color: var(--live-container-bg);
  transition-delay: .1s;
  transition-duration: .3s;
  position: relative;
}

.live-icon {
  background-color: #bcc0c7;
  border-radius: 10px;
  padding: 2px;
  width: 15px;
}

.blink {
  animation: reveal .7s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  place-items: center;
  height: 500px;
  color: #0c0c0c;
  width: 100%;
}

.general-11px-font-style {
  color: var(--H3Dark1);
  font-weight: 600;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.table-12px-fav-header {
  font-size: 12px;
  font-weight: 700;
  color: #6f8caa;
}

.pad-lock-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
  .loading-container {
    display: flex;
    justify-content: center;
    place-items: center;
    color: #1a3150;
    font-size: 1rem;
    font-weight: 600;
    min-height: 33rem;
  }

  .const-width-content {
    margin-top: 65px;
  }
}
